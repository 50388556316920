<template>
    <div id="buy">
        <!-- Form -->
        <section id="form">
            <div class="section-content section-content-xs style-basic">
                <div class="container">
                    <!-- Title -->
                    <div class="row">
                        <div class="col-12 text-center">
                            <p class="h1 styled-header font-weight-bold">{{calculated.name}}</p>
                            <p class="h4 color-basic-text font-weight-normal">Расчет стоимости банок с покрытием</p>
                        </div>
                    </div>

                    <!-- Calculated -->
                    <div class="row justify-content-center">
                        <div class="col-12 col-lg-8 col-xl-6">
                            <div class="overflow-scroll">
                                <table class="table mt-5 mb-3">
                                    <thead>
                                        <tr>
                                            <th scope="col" style="width:34%">Товар</th>
                                            <th scope="col" style="width:26%">Цена за шт.</th>
                                            <th scope="col" style="width:15%">Кол-во</th>
                                            <th scope="col" style="width:25%">Суммарная Цена</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="(value,name) in calculated.used" :key="name">
                                            <td scope="row">{{calculated.name + ' ' + name}}кг</td>
                                            <td>{{value.basePrice}} руб.</td>
                                            <td>{{value.amount}}</td>
                                            <td>{{value.price}} руб.</td>
                                        </tr>

                                        <tr v-for="(value,name) in calculated.additional" :key="name">
                                            <td scope="row">{{value.name + ' ' + name}}кг</td>
                                            <td>{{value.basePrice}} руб.</td>
                                            <td>{{value.amount}}</td>
                                            <td>{{value.price}} руб.</td>
                                        </tr>

                                        <tr>
                                            <td scope="" colspan="3">Всего</td>
                                            <td>{{calculated.price}} руб.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="mb-2">
                                <p class="h6 color-basic-text mb-0">*Возможная погрешность: от -15% до +15%.</p>
                            </div>
                            <div class="mb-5">
                                <p class="h6 color-basic-text mb-0">**Итоговая цена указана за материалы и не включает затраты на нанесение.</p>
                            </div>

                            <!-- Buy -->
                            <form method="POST" action="/req/index.php" class="bg-secondary-basic border-radius-default p-4">
                                <input name="request_type" value="buy" hidden>

                                <input name="material" :value="calculated.name" hidden>
                                <input name="meters" :value="calculated.meters" hidden>
                                <input name="kgs" :value="calculated.kgConsumption" hidden>

                                <input name="used" :value="JSON.stringify(calculated.used)" hidden>
                                <input name="price" :value="calculated.price" hidden>
                                
                                <!-- (Calculated) Required meters & price -->
                                <p class="h4 font-weight-bold">Требуется: {{calculated.meters}}м<sup>2</sup> ({{calculated.kgConsumption}}кг штукатурки{{calculated.optionsKgConsumption}})</p>
                                <p class="h4 font-weight-bold">Итого: {{calculated.price}} руб</p>

                                <!-- Name -->
                                <label class="h5">Ваше имя</label>
                                <input name="buyerName" type="text" placeholder="Введите ваше имя" required class="form-control form-control-lg border-md style-basic mb-3">

                                <!-- Contacts -->
                                <label class="h5">Ваши контакты</label>
                                <textarea name="buyerContacts" placeholder="Введите ваши контакты (Телефон/Мессенджер/Почта)" required class="form-control form-control-lg border-md style-basic mb-3"></textarea>

                                <div class="d-flex flex-column">
                                    <button type="submit" class="btn btn-md style-secondary-active default$ w-100 font-title m-auto">
                                        Отправить заявку
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'Buy',
    data: function() {
        return {
            calculated: this.getCalculated()
        }
    },
    mounted: function() {
        window.scrollTo(0,0);
    },
    methods: {
        getCalculated() {
            //GET params 'id','meters'
            var productId = undefined, meters = undefined, opt = undefined, tmp = [];
            var otherParams = {};
            location.search
                .substr(1)
                .split("&")
                .forEach(function (item) {
                    tmp = item.split("=");
                    if (tmp[0] === 'id') productId = decodeURIComponent(tmp[1]);
                    if (tmp[0] === 'meters') meters = decodeURIComponent(tmp[1]);
                    if (tmp[0] === 'opt') opt = decodeURIComponent(tmp[1]);
                    else
                        otherParams[tmp[0]] = tmp[1]
                });

            //if has params
            if(productId !== undefined && meters !== undefined) {
                var calculated = window.data.calculatePrice(productId, meters);
                calculated.meters = meters;
                calculated.optionsKgConsumption = ''
                calculated.additional = {};

                //has options
                if(opt && opt != 'none') {
                    var calculatedOpt = window.data.calculateOption(opt, meters);

                    //add consumption
                    var optionsTotalConsumption = calculatedOpt.kgConsumption;

                    //add to table
                    Object.keys(calculatedOpt.used).forEach(usedSize => {
                        var usedSizeObj = calculatedOpt.used[usedSize];

                        calculated.additional[usedSize] = {
                            name: calculatedOpt.name,

                            amount: usedSizeObj.amount,
                            basePrice: usedSizeObj.basePrice,
                            price: usedSizeObj.price,
                        }

                        //add to sum
                        calculated.price += usedSizeObj.price;
                    })

                    if(optionsTotalConsumption != 0)
                        calculated.optionsKgConsumption = `, ${optionsTotalConsumption}кг доп. материала`;
                }

                return calculated;
            }
            //else -> redirect to calculator
            else {
                this.$router.push('/calculator')
                return undefined;
            }
        }
    }
}
</script>